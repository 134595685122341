export default {
    namespaced: true,
    state: {
    },
    actions: {
    },
    mutations: {
    },
    getters: {}
}
